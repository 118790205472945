import React, { useState } from 'react';
import Loader from '../../components/Loader';

const GlassHiveNewsletterSignup = () => {
    const [submittingForm, setSubmittingForm] = useState(false);
    const [submissionError, setSubmissionError] = useState(false);
    const [submitSuccessful, setSubmissionSuccessful] = useState(false);

    const submitForm = e => {
        e.preventDefault();
        setSubmittingForm(true);
        setSubmissionError(false);

        const formName = document.getElementById('contact-form');

        fetch(formName.getAttribute('action'), {
            method: 'POST',
            body: new FormData(formName),
        }).then(res => {
            if (res.status === 200) {
                setSubmissionSuccessful(true);
            } else {
                setSubmittingForm(false);
                setSubmissionError(true);
            }
        });
    };

    return (
        <div className="card newsletter-card two-thirds">
            {!submitSuccessful && (
                <>
                    <h2>Join the GlassHive Community</h2>

                    <h5 className="max-width-420 centered-text">
                        Monthly company updates and marketing tips, delivered
                        straight to your inbox.
                    </h5>

                    <form
                        id="contact-form"
                        className="pink-form"
                        method="POST"
                        name="Contact Form"
                        action="/resources/#thanks"
                        encType="multipart/form-data"
                        netlify="true"
                        netlify-honeypot="bot-field"
                        data-category="Newsletter"
                        onSubmit={e => submitForm}
                    >
                        <input
                            type="hidden"
                            name="form-name"
                            value="contact-form"
                        />
                        <input type="hidden" name="bot-field" id="bot" />
                        {!submittingForm && (
                            <>
                                <input
                                    type="email"
                                    required
                                    name="email"
                                    placeholder="name@email.com"
                                />

                                <div className="submit-btn">
                                    <button id="sbmt-form-btn" type="submit">
                                        <img
                                            src={require('../../svgs/resources/mail.svg')}
                                            alt="mail"
                                        />
                                    </button>
                                </div>
                            </>
                        )}
                        {submissionError && (
                            <p id="error-msg">
                                Looks like there was a problem submitting your
                                form. Please try again.
                            </p>
                        )}
                        {submittingForm && <Loader borderColor="#f30070" />}
                    </form>
                </>
            )}
            {submitSuccessful && (
                <div className="contact-thank-you reach" id="thanks">
                    <h5>You have been added to our monthly newsletter.</h5>
                </div>
            )}
        </div>
    );
};

export default GlassHiveNewsletterSignup;
