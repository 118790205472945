import React from 'react';
import './style.scss';
import ShinyButton from '../ShinyButton';

const ResourceCard = ({ colLength, post, btn }) => {
    post = post.node;
    const category = post.categories[0].name
        ? post.categories[0].name.split(' ').join('-')
        : null;
    const featuredImageUrl = post.featured_media
        ? post.featured_media.localFile.url
        : require('../../images/resources/blog-placeholder.png');
    const button = btn ? (
        <div className="btn-container">
            <ShinyButton className="btn purple first" href={'/' + post.slug}>
                Read Book
            </ShinyButton>
        </div>
    ) : null;

    return (
        <a
            href={'/' + post.slug}
            className={'card ' + colLength + ' ' + category.toLowerCase()}
        >
            <div className="card-inner">
                <div className="top-img">
                    <img src={featuredImageUrl} alt="feat img thumbnail" />
                </div>
                <div className="card-text">
                    <h3 dangerouslySetInnerHTML={{ __html: post.title }}></h3>
                    <div className="excerpt">
                        <p
                            dangerouslySetInnerHTML={{ __html: post.excerpt }}
                        ></p>
                    </div>
                </div>
                {button}
            </div>
        </a>
    );
};

export default ResourceCard;
