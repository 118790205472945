import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Waypoint } from 'react-waypoint';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import JoinGlassHiveCallout from '../../components/JoinGlassHiveCallout';
import ResourceCard from '../../components/ResourceCard';
import GlassHiveNewsletterSignup from '../../components/GlassHiveNewsletterSignup/GlassHiveNewsletterSignup';

import './style.scss';

const Resources = ({ data }) => {
    const [showMoreBlogs, setShowMoreBlogs] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [dropdownFilter, setDropdownFilter] = useState('all');
    const [blogPosts] = useState(data.allWordpressPost.edges);
    const [filteredBlogPosts, setFilteredBlogPosts] = useState(blogPosts);

    useEffect(() => {
        let posts = blogPosts;

        if (searchValue) {
            posts = posts.filter(post => {
                const postTitle = post.node.title.toLowerCase();
                return postTitle.includes(searchValue.toLowerCase());
            });
        }

        if (dropdownFilter !== 'all') {
            posts = posts.filter(post => {
                const postCategory = post.node.categories[0].name
                    .split(' ')
                    .join('-')
                    .toLowerCase();

                return postCategory === dropdownFilter.toLowerCase();
            });
        }

        if (!showMoreBlogs) {
            posts = posts.slice(0, 7);
        }

        if (posts.length < 7) {
            setShowMoreBlogs(true);
        }

        setFilteredBlogPosts(posts);
    }, [searchValue, dropdownFilter, showMoreBlogs]);

    const setCategoryFilter = category => {
        setSearchValue('');
        setShowMoreBlogs(false);
        setDropdownFilter(category);
    };

    const setSearchTerm = event => {
        setSearchValue(event.target.value);
        setShowMoreBlogs(false);
    };

    return (
        <Layout navTheme="light">
            <Seo title="Resources" />
            <div className="resources-page reach">
                <Container className="purple-swish container-1">
                    <Row>
                        <Col className="white-text">
                            <h4 className="white-text">Featured Blog</h4>
                            <ResourceCard
                                colLength={'full'}
                                post={blogPosts[0]}
                            />
                        </Col>
                    </Row>
                </Container>
                <Container className="resource-content container-2">
                    <Row className="input-els">
                        <div className="flex justify-space-between flex-dir-row">
                            <div className="select">
                                <select
                                    onChange={e => {
                                        setCategoryFilter(e.target.value);
                                    }}
                                    value={dropdownFilter}
                                >
                                    <option value="all">All Categories</option>
                                    <option value="service-updates">
                                        Updates
                                    </option>
                                    <option value="blog">Blogs</option>
                                    <option value="guides">Guides</option>
                                    <option value="video">Videos</option>
                                </select>
                            </div>
                            <label className="hide-me" htmlFor="search-blogs">
                                search blogs
                            </label>
                            <input
                                type="text"
                                id="search-blogs"
                                name="search-blogs"
                                placeholder="Search"
                                value={searchValue}
                                onChange={e => {
                                    setSearchTerm(e);
                                }}
                            />
                        </div>
                    </Row>
                    <Row className="load-more-container">
                        {filteredBlogPosts.map((post, index) => {
                            return (
                                <React.Fragment
                                    key={'newsletter' + post.node.id}
                                >
                                    {index === 6 && (
                                        <GlassHiveNewsletterSignup />
                                    )}
                                    <ResourceCard
                                        colLength={'one-third'}
                                        post={post}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </Row>
                    {!showMoreBlogs && (
                        <Row id="loadMoreRow">
                            <a
                                className="btn load-more pink margin-left-50"
                                onClick={() => {
                                    setShowMoreBlogs(true);
                                }}
                            >
                                <span className="btn__mask"></span>
                                <span className="btn__text">Load More...</span>
                            </a>
                        </Row>
                    )}
                </Container>
                <Container className="container-3">
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector('.join-glasshive-callout')
                                .classList.add('animate');
                        }}
                    />
                    <Row>
                        <Col className="three-fourth">
                            <JoinGlassHiveCallout />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export const query = graphql`
    {
        allWordpressPost {
            edges {
                node {
                    id
                    title
                    date
                    excerpt
                    content
                    slug
                    featured_media {
                        localFile {
                            url
                        }
                    }
                    categories {
                        name
                    }
                }
            }
        }
    }
`;

export default Resources;
