import React from "react"
import { throwSignupConversionEvent } from '../../scripts/utils';
import "./style.scss"

const JoinGlassHive = () => {
  return (
		<div className="join-glasshive-callout">
			<div className="content">
				<h3 className="white-text margin-bottom-0">
					Get a taste of GlassHive
				</h3>
				<h4 className="white-text light-weight margin-bottom-0">
					Sign up today
				</h4>
			</div>
			<div className="action">
				<a 
                    onClick={() => throwSignupConversionEvent('https://app.glasshive.com/Account/Login?s=signup')} 
                    className="btn white create-free-account-button"
                >
					Join GlassHive
				</a>
			</div>
		</div>
	)
}

export default JoinGlassHive